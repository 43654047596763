import { Component, ChangeDetectorRef, OnDestroy, ViewChild } from "@angular/core";
import { Idle } from "@ng-idle/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from "@angular/router";
import { MediaMatcher } from "@angular/cdk/layout";
import { LoginService } from "./services/admin-plus/login.service";
import { User } from "src/app/web/login/user";
import { Location } from "@angular/common";
import { NavService } from "./services/admin-plus/nav.service";
import { BreadCrumb } from "./models/admin-plus/bread-crumbs";
import { AdminPlusService } from "./services/admin-plus/admin-plus.service";
import { CustomerService } from "./services/admin-plus/customer.service";
import { trigger, state, style, animate, transition } from "@angular/animations";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("in", style({ opacity: 1, transform: "translateX(0)" })),
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(-100%)" }),
        animate("300ms ease", style({ opacity: 1, transform: "translateX(0)" })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnDestroy {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  countdown = 30;
  idleDialog;
  modules;
  user: User;
  mobileQuery: MediaQueryList;
  isSideMenuOpen = true;
  private uri: string;

  @ViewChild("sidenav") sidenav;
  private _mobileQueryListener: () => void;

  constructor(
    public loginService: LoginService,
    private router: Router,
    private idle: Idle,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    public location: Location,
    private navService: NavService,
    private adminPlusService: AdminPlusService,
    private customerService: CustomerService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (
          this.loginService.isLoggedIn() &&
          this.customerService.customer?.subscription?.expired &&
          !["/login", "/logout", "/eua", "/account"].includes(event.url)
        ) {
          this.router.navigateByUrl("/account");
        } else {
          this.navService.loading.next(true);
        }
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.dialog.closeAll();
        this.navService.loading.next(false);
      }
    });
    
    location.onUrlChange((url) => {
      let uri = url.split("?")[0];
      uri = uri.split("#")[0];

      if (this.uri !== uri) {
        //window["Beacon"]("search", url != "/" ? url.replace(/\//g, " ").trim() : "login trial");
        const breadCrumb: BreadCrumb = this.adminPlusService.breadCrumbs.find((breadCrumb) =>
          url.startsWith(breadCrumb.url)
        );
        this.adminPlusService.breadCrumbLinks = breadCrumb ? JSON.parse(JSON.stringify(breadCrumb.links)) : [];
        this.uri = uri;
      }
    });
    
  }

  ngOnInit(): void {
    this.loginService.showLeftNavBreadcrumb = this.loginService.isLoggedIn();
    if (this.loginService.showLeftNavBreadcrumb) {
      this.isSideMenuOpen = false;
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  toggleSideMenu(): void {
    this.isSideMenuOpen = !this.isSideMenuOpen;
  }
}
